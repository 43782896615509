<template>
  <!-- prettier-ignore -->
  <div
    class="select"
    :class="classes"
  >
    <div
      class="select__trigger outline"
      :aria-activedescendant="activeDescendant"
      :aria-controls="dropdownId"
      aria-haspopup="listbox"
      :aria-expanded="dropdownRef?.isOpen"
      role="combobox"
      tabindex="0"
      @click="toggle"
      @keydown.enter.prevent="toggle"
      @keydown.up.prevent="toggle"
      @keydown.down.prevent="toggle"
    >
      <div class="trigger__container">
        <slot name="leading"></slot>

        <div class="trigger__content">
          <span
            class="content__label"
            v-html="autoLabel"
          />

          <slot name="selected">
            <span
              v-if="!$slots.selected && model !== null"
              class="content__value"
              v-html="selectedLabel"
            />
          </slot>
        </div>

        <template v-if="showIcon">
          <the-icon
            v-if="isInvalid"
            art="solid"
            class="invalid-icon"
            name="circle-exclamation"
          />
          <the-icon
            v-else-if="$props.hasIconValidIndicator && isValid"
            art="solid"
            class="valid-icon"
            name="circle-check"
          />
        </template>

        <slot name="trailing">
          <the-icon
            v-if="!$props.readonly"
            art="light"
            :name="dropdownRef?.isOpen ? 'chevron-up' : 'chevron-down'"
          />
        </slot>
      </div>
    </div>
    <div class="select__hint caption-regular">
      <span v-text="hint" />
    </div>

    <basic-dropdown-menu
      :id="dropdownId"
      ref="dropdownRef"
      class="select__dropdown"
      :forced-width="$props.forcedWidth"
      :name="$props.name"
      :options="$props.options"
      :selected="model"
      :size="$props.size"
      @select="selectItem"
    />
  </div>
</template>

<script setup>
/**
 * FIGMA: https://www.figma.com/file/3r1w8rChrH97KwxTcUblDL/EP-Basic-Library?node-id=244%3A6421&mode=dev
 * NOTES:
 * KNOWN ISSUES:
 *  - props.name set static for dropdown-menu?
 */
import { computed, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import useI18n from '@/hooks/useI18n'

import BasicDropdownMenu from '@/components/Basic/DropdownMenu'

// HOOKS
const { t } = useI18n()

// INIT
const model = defineModel({ type: [String, Object, Number] })
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  forcedWidth: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: '',
  },

  manualValidation: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    required: true,
  },

  options: {
    type: Array,
    required: true,
  },

  readonly: {
    type: Boolean,
    default: false,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// DATA
const KEY = uuidv4()
const dropdownRef = ref(null)
const selectedLabel = ref(null)

const dropdownId = `dropdown-${KEY}`

// COMPUTED
const activeDescendant = computed(() => {
  if (!dropdownRef.value?.isOpen) return ''
  const suffix = model.value || props.options[0].value
  return `${props.name}-${suffix}`
})

const autoLabel = computed(() => {
  let str = props.label
  if (isRequired.value) {
    str += ' *'
  }

  return str
})

const classes = computed(() => {
  return {
    [`select--${props.size}`]: true,
    'select--empty': isEmpty.value,
    'select--success': props.hasIconValidIndicator && isValid.value,
    'select--error': isInvalid.value,
    'select--disabled': isDisabled.value,
  }
})

const hint = computed(() => {
  if (isInvalid.value) {
    const error = props.v?.$errors[0]?.$validator
    if (error) return t(`form.error.${error}`)
  }
  return props.supportingText
})

const isDisabled = computed(() => {
  return props.disabled || props.readonly
})

const isEmpty = computed(() => {
  return model.value === undefined || model.value === null || model.value.length === 0
})

const isInvalid = computed(() => {
  return props.v?.$dirty && props.v?.$invalid
})

const isRequired = computed(() => {
  return !!props.v?.required
})

const isValid = computed(() => {
  return props.v?.$dirty && !props.v?.$invalid
})

const showIcon = computed(() => {
  return isInvalid.value || (props.hasIconValidIndicator && isValid.value)
})

// METHODS
function selectItem(item) {
  selectedLabel.value = item.label
  model.value = item.value

  if (!props.manualValidation) props.v?.$validate()
}

function toggle() {
  if (props.readonly) return

  dropdownRef.value.toggle()
}

// WATCHERS
watch(
  model,
  value => {
    // @NOTE: Why did we check, if there's no selectedLabel? With this, the selectedLabel will not update, when a change occurs and there's already something selected
    if (value /* && !selectedLabel.value*/) {
      const item = props.options.find(x => x.value === model.value)
      selectedLabel.value = item.label
    }
  },
  { immediate: true }
)
</script>

<style>
:root,
.surface-default {
  --dvp-select-color-background: var(--Input-fields-input-on-default-surface-fill);
  --dvp-select-color-active-indicator: var(--on-surface);
  --dvp-select-color-background-disabled: var(--Interaction-States-disabled-default);
  --dvp-select-color-label-disabled: var(--on-surface);
  --dvp-select-c-outline: var(--on-surface);
}

.surface-alternative {
  --dvp-select-color-background: var(--Input-fields-input-on-alternative-surface-fill);
  --dvp-select-color-active-indicator: var(--on-surface);
  --dvp-select-color-background-disabled: var(--Interaction-States-disabled-extended);
  --dvp-select-color-label-disabled: var(--on-surface);
  --dvp-select-c-outline: var(--on-surface);
}

.surface-inverted {
  --dvp-select-color-background: var(--Input-fields-input-on-Inverted-surface-fill);
  --dvp-select-color-active-indicator: var(--Input-fields-input-on_Inverted-surface-active-indicator);
  --dvp-select-color-background-disabled: var(--Interaction-States-disabled-inverse);
  --dvp-select-color-label-disabled: var(--Interaction-States-disabled-inverse-label);
  --dvp-select-c-outline: var(--on-inverse-surface-variant);
}
</style>
<style scoped>
.select {
  --dvp-select-color-label: var(--on-surface-medium);
  --dvp-select-color-container-border: var(--dvp-select-color-active-indicator);
  --dvp-select-color-hint: var(--on-surface-medium);

  display: inline-block;
  width: 100%;
  margin: 0 0 var(--fixed-spacing-fix-04);
  position: relative;

  &--success {
    --dvp-select-color-label: var(--on-surface-medium);
    --dvp-select-color-container-border: var(--secondary);
    --dvp-select-color-hint: var(--on-surface-medium);
  }

  &--error {
    --dvp-select-color-label: var(--error);
    --dvp-select-color-container-border: var(--error);
    --dvp-select-color-hint: var(--error);
  }

  &--disabled {
    cursor: default !important;
    user-select: none;

    .select__trigger {
      cursor: default;
      background-color: var(--dvp-select-color-background-disabled);
      color: var(--dvp-select-color-label-disabled);
    }
  }

  &--sm {
    --dvp-select-font-size-label: var(--font-size-regular-md);
    --dvp-select-letter-spacing-label: var(--letter-spacing-regular-md);
    --dvp-select-line-height-label: var(--line-height-regular-md);
  }

  &--md {
    --dvp-select-font-size-label: var(--font-size-regular-xl);
    --dvp-select-letter-spacing-label: var(--letter-spacing-regular-xl);
    --dvp-select-line-height-label: calc(var(--line-height-regular-xl) - var(--fixed-spacing-fix-01));
  }

  &:deep(.icon) {
    font-size: var(--font-size-regular-md);
    color: var(--on-surface-medium);
  }
}

.select__trigger {
  background-color: var(--dvp-select-color-background);
  border-radius: var(--Input-fields-input-border-radius);
  height: var(--spacing-san-spacing-12);
  padding: 0 var(--fixed-spacing-fix-04);
  cursor: pointer;

  &.has-outline:focus-visible {
    outline: 2px solid var(--dvp-select-c-outline);
    outline-offset: var(--fixed-spacing-fix-01);
    border-radius: var(--fixed-border-radius-fix-02);
  }
}

.trigger__container {
  display: flex;
  align-items: center;
  gap: var(--fixed-spacing-fix-04);
  height: 100%;
  box-shadow: inset 0 -1px 0 var(--dvp-select-color-container-border);

  white-space: nowrap;
}

.trigger__content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
}

.content__label {
  color: var(--dvp-select-color-label);
}

.select--empty .content__label,
.content__value {
  display: inline-block;
  overflow: hidden;

  font-size: var(--dvp-select-font-size-label);
  letter-spacing: var(--dvp-select-letter-spacing-label);
  line-height: var(--dvp-select-line-height-label);
  text-overflow: ellipsis;
}

.select:not(.select--empty) .content__label {
  font-size: var(--font-size-regular-xs);
  line-height: var(--line-height-regular-xs);
  letter-spacing: var(--letter-spacing-regular-xs);
}

.valid-icon.icon {
  color: var(--secondary);
}

.invalid-icon.icon {
  color: var(--error);
}

.select__hint {
  --dvp-select-indent-hint: var(--fixed-spacing-fix-04);

  color: var(--dvp-select-color-hint);
  margin: 0 0 0 var(--dvp-select-indent-hint);
  height: var(--line-height-regular-xs);
  display: inline-block;
  width: 100%;

  & span {
    display: inline-block;
    width: calc(100% - (2 * var(--dvp-select-indent-hint)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select__dropdown {
  top: calc(100% - var(--line-height-regular-xs));
}
</style>
